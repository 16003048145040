import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useForm } from "react-hook-form";

function App() {

  const [result, setResult] = useState(null)
  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      nombre: 'Ruslan',
      apellido: 'Gonzalez',
      hobbies: [
        {
          id: 1,
          nombre: 'fotografía'
        },
        {
          id: 2,
          nombre: 'programación'
        }
      ]
    }
  })
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'hobbies'
  })
  const onSubmit = data => (setResult(data))

  return (
    <div className="App">
      <h1>Field Array for uncontrolled form</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Nombre: {' '}
          <input {...register('nombre')} type="text" />
        </label>
        <label>
          Apellido: {' '}
          <input {...register('apellido')} type="text" />
        </label>

        <fieldset>
          <legend>Hobbies</legend>
          {
            fields.map((item, index) => (
              <div key={item.id} className="hobbies-container">
                <div className="move-arrows">
                  {index !== 0 && <span onClick={() => move(index, index - 1)}>&#x21e7;</span>}
                  {index !== (fields.length - 1) && <span onClick={() => move(index, index + 1)}>&#x21e9;</span>}
                </div>
                <input
                  type="text"
                  {...register(`hobbies.${index}.nombre`, { required: true })}
                  defaultValue={item.nombre}
                />
                <button title="remove" type="button" onClick={() => remove(index)}>Delete</button>
              </div>
            ))
          }
          <button title="add" type="button" onClick={() => append({ id: '', nombre: '' })}>Add</button>
        </fieldset>

        <button type="submit">Submit</button>
      </form>
      {
        result &&
        <fieldset>
          <legend>Result</legend>
          <pre>
            {
              JSON.stringify(result, null, 3)
            }
          </pre>
        </fieldset>
      }
    </div>
  );
}

export default App;
